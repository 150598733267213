<template>
  <div>
    <Header 
      button="Adicionar Pergunta"
      title="Gerenciamento da FAQ"
      subtitle="Gerencie o guia de perguntas frequentes, organizado por categorias."
      @click="itemFocus = { title: '', content: '', categorie: '' }, $modal.show('faq')"
    >
      <Select v-if="categoriesUsed.length" class="mt-5" name="categoria" label="Ordenar por" :values="categoriesUsed" v-model="categorieFilter" @change="getFaqs()" />
    </Header>
    <div v-if="faqs.length" class="max-w-7xl mx-auto mt-3 px-4 sm:px-6 lg:px-8">
      <div class="bg-white shadow overflow-hidden rounded-md">
        <ul class="divide-y divide-gray-200">
          <li v-for="(item, index) in faqs" :key="index">
            <div class="flex items-center justify-between px-4 py-4 sm:px-6 hover:bg-gray-50">
              <div class="min-w-0 flex-1 flex items-center">
                <p class="font-medium text-sm text-primary truncate">{{ item.title }}</p>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 min-w-max text-gray-500" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" /></svg>
                <p class="text-sm text-gray-600 truncate">{{ item.content }}</p>
              </div>
              <button @click="itemFocus = item, editFocus.title = item.title, editFocus.content = item.content, $modal.show('edit')" type="button" class="ml-2 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" /></svg>
              </button>
              <button @click="itemFocus = item, $modal.show('confirm')" type="button" class="ml-2 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" /></svg>
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <t-modal name="faq" class="z-45">
      <p class="text-base font-medium text-gray-800">Nova Pergunta</p>
      <form @submit.prevent class="mt-4">
        <div class="grid grid-cols-2 gap-2">
          <div class="col-span-2">
            <Select name="categoria" label="Categoria" :values="categoriesAll.map(item => item.name)" v-model="itemFocus.categorie" />
          </div>
          <div class="col-span-2">
            <label for="title" class="block text-sm font-medium text-gray-700">Pergunta</label>
            <input v-model="itemFocus.title" type="text" name="title" id="title" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
          </div>
          <div class="col-span-2">
            <label for="content" class="block text-sm font-medium text-gray-700">Conteúdo</label>
            <textarea v-model="itemFocus.content" rows="3" type="text" name="content" id="content" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"></textarea>
          </div>
        </div>
        <button @click="saveFaq()" type="submit" class="mt-4 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
          Salvar
        </button>
      </form>
    </t-modal> 
    <t-modal name="edit" class="z-45">
      <p class="text-base font-medium text-gray-800">Editar Pergunta</p>
      <form @submit.prevent class="mt-4">
        <div class="grid grid-cols-2 gap-2">
          <div class="col-span-2">
            <label for="title" class="block text-sm font-medium text-gray-700">Pergunta</label>
            <input v-model="editFocus.title" type="text" name="title" id="title" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
          </div>
          <div class="col-span-2">
            <label for="content" class="block text-sm font-medium text-gray-700">Conteúdo</label>
            <textarea v-model="editFocus.content" rows="3" type="text" name="content" id="content" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"></textarea>
          </div>
        </div>
        <button @click="editFaq()" type="submit" class="mt-4 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
          Salvar
        </button>
      </form>
    </t-modal>
    <t-modal name="confirm" class="z-45">
      <p class="text-base font-medium text-gray-800">Apagar Pergunta</p>
      <form @submit.prevent class="mt-8 text-center">
        <p class="font-medium">Tem certeza que deseja excluir essa pergunta?</p>
        <p class="text-gray-600 text-sm mb-4">Ela deixará de ser vinculada na página de suporte.</p>
        <button @click="deleteFaq()" type="submit" class="mt-4 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
          Apagar
        </button>
      </form>
    </t-modal> 
  </div>
</template>

<script>
import { firebase, faqsColl, listingsColl } from '@/firebase'

export default {
  components: { 
    Select: () => import('@/components/form/select'),
    Header: () => import('@/components/layout/header'),
  },

  data() {
    return {
      faqs: [],
      categoriesAll: [],
      categorieFilter: '',
      itemFocus: { title: '', content: '', categorie: '' },
      editFocus: { title: '', content: '' },
    }
  },

  computed: {
    categoriesUsed: function() {
      return this.categoriesAll.filter((item) => { return item.faqs > 0 }).map((item) => { return item.name })
    },
  },

  mounted() { this.getCategories() },

  methods: {
    async getCategories() {
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      await listingsColl.doc('Categories').get()
      .then((result) => { this.categoriesAll = result.data().available })
      .catch(() => { this.$toast.error('Falha ao obter as categorias, tente novamente') })
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    },

    async getFaqs() {
      if (!this.categorieFilter) { this.faqs = []; return }
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      this.faqs = []
      await faqsColl.doc(this.categorieFilter).get()
      .then((result) => { if (result.exists) { this.faqs = result.data().items } })
      .catch(() => { this.$toast.error('Falha ao obter as perguntas, tente novamente') })
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    },

    async saveFaq() {
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      await faqsColl.doc(this.itemFocus.categorie).get()
      .then(async (result) => {
        if (result.exists) {
          await faqsColl.doc(this.itemFocus.categorie).update({ items: firebase.firestore.FieldValue.arrayUnion({ title: this.itemFocus.title, content: this.itemFocus.content }) })
          .then(() => { this.$toast.success('Pergunta adicionada com sucesso') })
          .catch(() => { this.$toast.error('Falha ao obter as perguntas, tente novamente') })
        } else {
          await faqsColl.doc(this.itemFocus.categorie).set({ items: [{ title: this.itemFocus.title, content: this.itemFocus.content }] })
          .then(() => { this.$toast.success('Pergunta adicionada com sucesso') })
          .catch(() => { this.$toast.error('Falha ao obter as perguntas, tente novamente') })
        }
      })
      .catch(() => { this.$toast.error('Falha ao salvar a pergunta, tente novamente') })
      
      let categorie = this.categoriesAll.filter(item => item.name === this.itemFocus.categorie)[0]
      
      await listingsColl.doc('Categories').update({ available: firebase.firestore.FieldValue.arrayRemove(categorie) })
      categorie.faqs++
      await listingsColl.doc('Categories').update({ available: firebase.firestore.FieldValue.arrayUnion(categorie) })

      this.itemFocus = { title: '', content: '', categorie: '' }
      this.categorieFilter = ''
      this.$modal.hide('faq')
      this.getCategories()
      this.faqs = []
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    },

    async editFaq() {
      const index = this.faqs.findIndex(item => item === this.itemFocus)
      if (index < 0) { return }
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      this.faqs[index].title = this.editFocus.title
      this.faqs[index].content = this.editFocus.content
      await faqsColl.doc(this.categorieFilter).update({ items: this.faqs })
      .then(() => { this.$toast.success('Pergunta alterada com sucesso') })
      .catch(() => { this.$toast.error('Falha ao editar a pergunta, tente novamente') })
      this.$modal.hide('edit')
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    },

    async deleteFaq() {
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      await faqsColl.doc(this.categorieFilter).update({ items: firebase.firestore.FieldValue.arrayRemove(this.itemFocus) })
      .then(() => { this.$toast.success('Pergunta excluída com sucesso') })
      .catch(() => { this.$toast.error('Falha ao excluír a pergunta, tente novamente') })

      let categorie = this.categoriesAll.filter(item => item.name === this.categorieFilter)[0]
      
      await listingsColl.doc('Categories').update({ available: firebase.firestore.FieldValue.arrayRemove(categorie) })
      categorie.faqs--
      await listingsColl.doc('Categories').update({ available: firebase.firestore.FieldValue.arrayUnion(categorie) })
      
      this.itemFocus = { title: '', content: '', categorie: '' }
      this.$modal.hide('confirm')
      this.categorieFilter = ''
      this.getCategories()
      this.faqs = []
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    }
  }
}
</script>